<template>
  <b-container fluid>
    <b-row
      class="py-5"
      align-h="center"
    >
      <b-col xl="3" sm="4" class="text-center">
        <b-form @submit="onSubmit">
          <b-img
            class="mb-4"
            style="width:144px;"
            rounded
            src="@/assets/fitfam-logo-white.png"
          ></b-img>
          <h1 class="h3 mb-3 font-weight-normal">Please Log In</h1>
          <b-alert
            variant="danger"
            :show="showErrors"
          >Incorrect email or password, please try again</b-alert>
          <b-form-group>
            <b-form-input
              v-model="email"
              required
              placeholder="Email"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-input
              v-model="password"
              required
              type="password"
              placeholder="Password"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" block size="lg" variant="primary">Log In</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'login-index',
  data () {
    return {
      email: '',
      password: '',
      showErrors: false
    }
  },
  methods: {
    async onSubmit (e) {
      e.preventDefault()
      this.showErrors = false
      const { email, password } = this
      const data = { email, password }
      const method = 'post'
      const url = `${this.$config.apiUrl}/login/basic`
      try {
        const res = await this.axios({
          method,
          url,
          data
        })
        const { token } = res.data
        localStorage.setItem('token', token)
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        this.$root.token = token
        this.$root.loggedIn = true
        this.$router.replace('/admin/dashboard')
        return
      } catch (err) {
        this.showErrors = true
        // console.log('request failed', err)
      }
    }
  }
}
</script>

<style>

</style>
